@import '../../../assets/themes/variables';

.AppFooter {
  font-size: 0.8em;
  height: 40px;
  color: $gray-600;

  .app-footer__inner {
    padding: 0 30px;
    height: 100%;
  }

  .app-footer-right {
    align-items: center;
  }

  ul {
    margin-bottom: 0;

    li {
      display: inline-block;

      a {
        display: flex;
        align-items: center;
        color: $gray-600;
        padding: 0 0 0 8px;
        
        &:before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 12px;
          background: $gray-400;
          margin-right: 8px;
        }
      }

      &:first-child {
        a {
          &:before {
            content: "";
            width: 0;
          }
        }
      }
    }
  }
}