// Variables

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: #ee0979 !default;
$secondary: #ff6a00 !default;
$info: #30b1ff !default;
$danger: #cc0000 !default;
$text: $gray-700 !default;
