.LoadingIndicator {
  &.block {
    min-height: 300px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
