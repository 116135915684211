.AlertModal {
  &.center {
    display: flex;
    align-items: center;

    .rodal-dialog {
      position: relative;
      top: 0;
    }
  }
}