// User Pages
$logo-height: 60px;

.app-logo {
  height: $logo-height;
  background: transparent url('utils/images/logo-light.png') 0 0 no-repeat;
  background-size: contain;
}

.app-logo-inverse {
  height: $logo-height;
  background: transparent url('utils/images/logo-dark.png') 0 0 no-repeat;
  background-size: contain;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}