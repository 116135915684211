@import './variables';

form.extended {
  .form-control {
    height: auto;
  }

  .form-group.inputBox:not(.checkbox) {
    position: relative;
    margin-bottom: 1rem;

    input,
    .input,
    label,
    .rbt-input-multi {
      height: 3.125rem;
      padding: 0.75rem;
    }

    input,
    .input {
      padding-top: 1rem;
      padding-bottom: 0;
    }

    .rbt-input-multi {
      padding-top: 1.2rem;

      input {
        height: auto;
      }

      .rbt-token {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .rbt-input-hint {
      display: none !important;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      margin-bottom: 0; /* Override default `<label>` margin */
      line-height: 1.5;
      color: $text;
      pointer-events: none;
      cursor: text; /* Match the input under the label */
      border: 1px solid transparent;
      border-radius: 0.25rem;
      transition: all 0.1s ease-in-out;
    }

    input::-webkit-input-placeholder {
      color: transparent;
    }

    input:-ms-input-placeholder {
      color: transparent;
    }

    input::-ms-input-placeholder {
      color: transparent;
    }

    input::-moz-placeholder {
      color: transparent;
    }

    input::placeholder {
      color: transparent;
      font-style: italic;
    }

    &.hasValue,
    &.hasFocus {
      label {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 12px;
        color: #777;
      }
    }

    &.hasError {
      input,
      .input {
        border-color: transparentize($color: $danger, $amount: 0.5);
        border-width: 3px;
      }
    }
  }

  /* Fallback for Edge
  -------------------------------------------------- */
  @supports (-ms-ime-align: auto) {
    .form-label-group {
      label {
        display: none;
      }
      input::-ms-input-placeholder {
        color: #777;
      }
    }
  }

  /* Fallback for IE
  -------------------------------------------------- */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group {
      label {
        display: none;
      }
      input:-ms-input-placeholder {
        color: #777;
      }
    }
  }

  .addNewItemLoading {
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
  }

  label {
    z-index: 1;
  }
}
