.AppHeader {
  .btn-icon-wrapper {
    line-height: inherit;
    margin: 0 !important;
  }

  .app-header-left {
    flex-grow: 1;
    padding-right: 50px;
  }

  .search-wrapper {
    width: 100% !important;

    &.active {
      .input-holder {
        width: 100%;
      }
    }
  }

  .profileButton {
    display: flex;
    align-items: center;

    > .AvatarIcon {
      width: 44px;
      height: 44px;
    }
  }
}
