.Button {
  &.d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
  }

  &.btn-outline {
    border-width: 1px;
    border-style: solid;
    border-color: red;
  }

  &.btn-icon-only {
    padding: 0;

    &.btn-circle,
    &.btn-square {
      width: 54px;
      height: 54px;

      &.btn-size-small {
        width: 38px;
        height: 38px;
      }
    }

    &.btn-circle {
      border-radius: 50%;
      overflow: hidden;
    }

    &.btn-square {
      border-radius: 0.25rem !important;
    }
  }

  &.btn-badge {
    font-size: 11px;
    font-weight: 500;
    padding: 4px 8px;
    margin: 0;
    text-transform: unset;

    &:not(:first-child) {
      margin-left: 5px;
    }

    &:hover {
      color: white;
      opacity: 0.8;
    }
  }
}