@import '../../../assets/themes/variables';

.VerticalNav {
  $buttonHeight: 70px;

  position: relative;
  width: 100%;
  padding-bottom: $buttonHeight;

  .scrollbar-container {
    padding-bottom: 20px;
  }

  .chatSidebarContainer {
    .card-body {
      padding: 0;
    }
  }

  .btn-icon-wrapper {
    opacity: 1;

    &.fas {
      line-height: inherit;
      margin: 0 0 -5px 0;
    }
  }

  [class*="col-"] {
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 0 !important;

    &:not(:first-child) {
      border-left: 1px solid #dee2e6 !important;
    }
  }

  .navGroupButtons {
    background: #fafbfc;
    box-shadow: 0.3rem -0.46875rem 2.1875rem rgba(8, 10, 37, 0.02), 0.3rem -0.9375rem 1.40625rem rgba(8, 10, 37, 0.02), 0.3rem -0.25rem 0.53125rem rgba(8, 10, 37, 0.04), 0.3rem -0.125rem 0.1875rem rgba(8, 10, 37, 0.02);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $buttonHeight;
    z-index: 16;
    
    .btn {
      padding: 0;
      width: 100%;
      height: 100%;

      span {
        font-size: 0.9em;
      }
    }

    [class*="col"] {
      width: 20%;
      border-top: 1px solid #dee2e6 !important;
      border-bottom: 1px solid #dee2e6 !important;
      border-right: 0 !important;
  
      &:not(:first-child) {
        border-left: 1px solid #dee2e6 !important;
      }
    }
  }

  .app-sidebar__inner {
    padding-bottom: 0;

    &.chat,
    &.inbox {
      padding-top: 0;  
      padding-right: 0;  
      padding-left: 0;  
    }
  }
}
