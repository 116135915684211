.AvatarIcon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .imageDiv {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
