.PageNotFound {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: white url(../../assets/utils/images/originals/404.jpg) no-repeat center bottom;
  text-align: center;
  padding-top: 100px;

  h1 {
    margin-bottom: 20px;
  }

  .btn {
    margin-top: 10px;
  }
}